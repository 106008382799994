<template>
    <div id="login" class="">
        <img class="logo" :src="require('@/assets/logo.svg')" width="170" height="95" />
        <form @submit.prevent="login" class="login_form" :class="{'is-loading' : isRequesting }">
            <h1><span v-if="!passwordRecovery">{{ $t('login_title') }}</span>
                <span v-else>{{ $t('password_recovery_title') }}</span></h1>

            <p class="form-fieldwrap" :class="{'has-error' : loginError}">
                <label><span>{{ $t('login_username') }}</span></label>
                <input @focus="inputActivate"
                       @blur="inputDeactivate" type="text"
                       name="username"
                       autocomplete="username"
                       v-model.trim="username" />
            </p>

            <p class="form-fieldwrap" :class="{'has-error' : loginError}" v-if="passwordRecovery === false">
                <label class="field_wrap">
                    <span>{{ $t('login_password') }}</span></label>
                <input @focus="inputActivate"
                       @blur="inputDeactivate" type="password"
                       autocomplete="current-password"
                       name="password"
                       v-model.trim="password" />
            </p>

            <p class="error" v-if="loginError">{{$t('login_error_wrong_userdata')}}</p>

            <p class="submit" v-if="token">
                <button type="submit" class="button button--primary" v-on:animationend="goToMain">
                    <span v-if="!passwordRecovery">{{ $t('login_button') }}</span>
                    <span v-else>{{ $t('password_button') }}</span>
                </button>
                <button type="button" class="button button--secondary" @click="passwordRecovery = !passwordRecovery ">
                    <span v-if="!passwordRecovery">{{ $t('password_recovery') }}</span>
                    <span v-else>{{ $t('back') }}</span>
                </button>
            </p>
        </form>
        <popup-modal ref="modal">
            <div v-html="message" class="m-b-2"></div>
            <button @click="this.$refs.modal.close" class="button button--small button--primary">{{ $t('close') }}</button>
        </popup-modal>
        <!--        <router-link class="footer-link footer-link&#45;&#45;emergency" :to="{ name : 'Notfall'}"><img class="" :src="require('@/assets/images/ic_notfall.svg')" /><br />Notfall</router-link>-->
    </div>
</template>

<script>
import { AUTH_REQUEST, PASSWORD_RECOVER } from "../utils/store/actions/auth";
import { defineAsyncComponent } from 'vue';
import api, { ApiDefaults } from '../utils/api_call';

export default {
	name: "KappLogin",
	data() {
		return {
			username: '',
			password: '',
			loginError: false,
			isRequesting: false,
			buttonImage: '', //require('@/assets/images/logo_grey.svg')
			passwordRecovery: false,
            message: '',
            token: ''
		};
	},
	components: {
		PopupModal: defineAsyncComponent(() => import('@/components/Plugins/PopupModal'))
    },
	props: [],
	computed: {
		buttonColor() {
			return this.isRequesting ? 'red' : 'standard';
		}
	},
	methods: {
		getToken: function(){
			let apiParams = Object.assign({}, ApiDefaults, {
				method: 'get',
				params: {
					id: 8,
					type: 10,
					'tx_c3json_jsondatauser[action]' :'token'
				}});
			api(apiParams).then( (response) => {
				if( response.status === 200 || response.status === 'OK') {
					console.log(response);
					this.token = response.data.token;
				}
				else {
					this.loginError = 'No Token';
                }
			}).catch( (response) => {
				this.loginError = response.data.message
            })
        },
		goToMain: function () {

			if ( false === this.$store.getters.isAuthenticated ) this.$router.push({name: 'Login'});
		},
		usernameValid: function ( notEmpty = false ) {
			let isValid = true;

			if ( this.username.length < 2 )
				isValid = false;

			/** exceptions */
			if ( !notEmpty && this.username.length === 0 )
				isValid = true;

			return isValid;
		},
		passwordValid: function ( notEmpty = false ) {
			let isValid = true;

			if ( this.password.length < 4 )
				isValid = false;

			/** exceptions */
			if ( !notEmpty && this.password.length === 0 )
				isValid = true;

			return isValid;
		},
		login: function () {
			const {username, password, token} = this;

			if ( this.passwordRecovery ) {
				if ( this.usernameValid(true) ) {
					this.isRequesting = true;
					this.loginError = false;
					this.$store.dispatch(PASSWORD_RECOVER, username).then(() => {
						this.isRequesting = false;
						this.passwordRecovery = false;
					});
				}
			}
			else {

				if ( this.usernameValid(true) && this.passwordValid(true) ) {
					this.isRequesting = true;
					this.loginError = false;
					this.$store.dispatch(AUTH_REQUEST, {username, password, token})
						.then((result) => {

							this.isRequesting = false;
							if( result.newuser === true ) {

								this.message =`<h2>${this.$t('user_created_title')}</h2><p>${this.$t('user_created')}</p>`;
								let modal = this.$refs.modal;
                                modal.open();
								setTimeout(function(){
									modal.close();
                                },5000);
                            }
							else {
								this.$router.push({name: 'Main'});
							}
						})
						.catch(( err ) => {
							this.isRequesting = false;
							this.loginError = err;
						});
				}
				else {
					this.isRequesting = false;
					this.loginError = true;
				}
			}
		},

		inputActivate: function () {
			// document.querySelector('body').style.overflow = 'auto';
		},
		inputDeactivate: function () {
			// document.querySelector('body').style.overflow = 'hidden';
		}
	},
	watch: {
		username: function () {
			this.loginError = false;
		},
		password: function () {
			this.loginError = false;
		}
	},
    created () {
		this.getToken();
    }
};
</script>

<style scoped>
:root {
    --color-error: #E4223C;
}

#login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - 128px);
    max-width: 360px;
    margin: 0 auto;
}




.login_form.is-loading .field_wrap {
    visibility: hidden;
}


@keyframes sizeInButton {
    from {
        transform: scale(.8095238);
    }
}

@keyframes pulseColor {
    0% {
        box-shadow: 0 0 80px rgba(228, 34, 60, .0);
    }
    50% {
        box-shadow: 0 0 80px rgba(228, 34, 60, .7);
        transform: scale(1.19047619047619);
    }
    100% {
        box-shadow: 0 0 80px rgba(228, 34, 60, .4);
    }
}
</style>
